<template>
  <b-field>
    <b-input
        v-if="type==='filter'"
        v-debounce:300ms="getFiltered"
        placeholder="Subject"
        :loading="loading"
        :icon="$tc('icons.subjects')"
        :icon-right="$tc('icons.close-circle')"
        :icon-right-clickable="true"
        v-model="searchTerm"
        v-on:icon-right-click="clear();getFiltered('')"
    ></b-input>
    <b-autocomplete
        v-if="type==='autocomplete'"
        v-debounce:300ms="getFiltered"
        ref="autocomplete"
        :data="searchResponse"
        autocomplete="off"
        :loading="loading"
        :icon="$tc('icons.subjects')"
        v-model="searchTerm"
        placeholder="Subject"
        :clear-on-select="false"
        :custom-formatter="thing=> name(thing)"
        @select="option => select(option)"
    >
      <template slot-scope="props">
        <p>{{ props.option.long_name }} | {{props.option.name}}</p>
      </template>
    </b-autocomplete>
  </b-field>
</template>

<script>
import Subject from "@/models/Subject";

export default {
  name: "SubjectsFilter",
  data: function () {
    return {
      loading: false,
      searchTerm: '',
      searchResponse: null
    };
  },
  props: {

    selected_subject_id: {
      type: Number, required: false
    },
    mode: {
      type: String, default() {
        return 'insertOrUpdate'
      }
    },
    type: {
      type: String, default() {
        return 'filter'
      }
    },
    year:{
      type:Number,
      default(){
      return  this.$store.state.dates.year
      }
    },
    campus_id: {
      type: Number, default() {
        return null
      }
    },
    stage_id: {
      type: Number, default() {
        return null;
      }
    }
  },
  watch: {
    selected_subject_id(newValue) {
      if (newValue !== null) {
        Subject.FetchById(this.selected_subject_id).then(({entities}) => {
          this.searchResponse = entities.subjects
          this.$refs.autocomplete.setSelected(entities.subjects[0])

        })
      }
    }
  },
  mounted() {
    if (this.selected_subject_id) {
      Subject.FetchById(this.selected_subject_id).then(({entities}) => {
        this.searchResponse = entities.subjects
        this.$refs.autocomplete.setSelected(entities.subjects[0])

      })
    }
  },
  methods: {
    name(thing) {
      return `${thing.long_name} | ${thing.name}`
    },
    select(option) {
      if (option === null) {
        this.clear()
      } else {
        this.$emit('selected', option)
      }
    },

    clear() {
      this.searchTerm = ''
      this.searchResponse = [];
      this.$emit('cleared')
    },
    getFiltered(text) {
      this.loading = true;
      Subject.FetchAll({page: 1, limit: 999}, {
        ...{search: text}, ...(this.campus_id ? {
          campus_id: this.campus_id
        } : {}), ...(this.stage_id ? {
          stage_id: this.stage_id
        } : {}), ...(this.year ? {
          year: this.year
        } : {})
      }, ['class_groups'], this.mode).then((result) => {
        this.loading = false;

        if (result.entities) {
          this.searchResponse = result.entities.subjects;
          if (this.type === 'filter') {
            this.$emit('filtered', {result: result, term: this.searchTerm})
          }
        } else {
          this.$emit('filtered', {term: this.searchTerm})

          this.searchResponse = [];
        }
      });

    }
  }
}
</script>

<style scoped>

</style>
